<template>
  <div class="content-main-top-container">
    <div class="lp-account-title">솔루션 이용신청 / 매월 정기결제</div>
    <section class="section">
      <div class="container lp-account-container">
        <div class="columns">
          <div class="column is-6">
            <div class="lp-account-menu-title">신청정보</div>
            <div class="lp-card">
              <div class="lp-card-title">테마 및 요금제</div>
              <div class="flex" style="margin-bottom: 32px">
                <div :style="imgStyle"></div>
                <div style="margin-left:12px">
                  <div class="lp-account-menu-title">{{ basket.title }}</div>
                  <div>{{ basket.priceTypeName }} / {{ basket.price[basket.priceType].billing|currencyNum }}원(월 정기결제)</div>
                </div>
              </div>

              <div class="lp-card-title">개설정보 입력</div>

              <div>
                <div class="lp-input-box">
                  <input type="text"
                         placeholder="서비스 이름"
                         :value="serviceName"
                         @input="e => serviceName = e.target.value">
                </div>
                <div class="lp-input-box">
                  <input type="text" style="width:112px"
                         placeholder="URL"
                         :value="url"
                         @input="e => url = e.target.value">
                  <div class="suffix">. launchpack.co.kr</div>
                </div>

                <div class="lp-info">* 도메인은 개설 후 변경 가능합니다</div>
              </div>


            </div>
          </div>

          <div class="column is-6">
            <div class="lp-account-menu-title">결제정보</div>
            <div class="lp-card">
              <div class="lp-account-info-detail">
                <table class="table" style="width:100%">
                  <thead>
                  <tr>
                    <th>내역</th><th>기본금액</th><th>할인</th><th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width:120px">이용료</td>
                      <td>687,500원</td>
                      <td>-177,500원</td>
                      <td>{{ basket.price[basket.priceType].billing|currencyNum }}원</td>
                    </tr>
                    <tr><td style="width:120px">설치비</td><td>0원</td><td>0원</td><td>0원</td></tr>
                  </tbody>
                </table>
              </div>

              <div class="lp-account-info">
                <div>총 결제금액(VAT 별도)</div><div><span class="lp-total-price">{{ basket.price[basket.priceType].billing|currencyNum }}</span>원</div>
              </div>
            </div>
            <div style="margin-top:12px">
              <div><label class="checkbox"><input type="checkbox" v-model="agree.billing">매월 정기 결제에 동의</label></div>
              <div><label class="checkbox"><input type="checkbox" v-model="agree.limit">이용약관 및 하단 결제 안내사항에 동의</label></div>
              <button class="button" @click="clickAccount">결제하기</button>
            </div>
          </div>
        </div>
        <div class="info">{{ info }}</div>
      </div>
    </section>
    <billing-pc v-if="isBillingPc"></billing-pc>
    <billing v-if="isBilling"></billing>
  </div>
</template>

<script>
  import moment from 'moment'
  import BillingPc from "./oldBillingPc";
  import Billing from "./oldBilling";
  export default {
    components: {
      Billing,
      BillingPc
    },
    name: "account",

    created() {
      if(this.$route.query.result !== undefined) {
        this.account();
      }
    },

    data() {
      return {
        isBillingPc: false,
        isBilling: false,
        serviceName: '',
        url: '',
        agree: {
          billing: false,
          limit: false
        },
        info: '정기결제 이용 시 가능한 결제수단은 신용카드입니다.\n' +
        '결제취소는 결제 후 7일 내 서비스 미 이용 시 가능하며, 고객센터(1566-7727)나 1:1문의로 신청하세요.\n' +
        '결제 후 7일 경과 또는 서비스 이용 시에는 중도 해지 및 이에 따른 환불이 가능합니다.\n' +
        '결제 수단의 정지/한도초과/정보변경 등의 사유로 결제상태가 변경될 경우, 결제 및 서비스가 일시 정지되며 정상으로 전환되거나 직접 일시 정지 해제를 하시면 다시 결제 및 서비스가 시작됩니다.'
      }
    },
    computed: {
      basket() {
        let basket = this.$store.getters.basket;
        if(basket.priceType === 0)
          basket.priceTypeName = 'Start-up 요금제';
        else
          basket.priceTypeName = 'Enterprise 요금제';
        return basket;
      },

      imgStyle() {
        let deco = {
          width: '48px',
          height: '48px',
          backgroundImage: `url(${ this.basket.img })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }

        return deco;
      }
    },

    methods: {
      isValid(value, name, preValid) {
        if(preValid !== undefined && preValid === false) return preValid;

        let valid = true;
        if(value === '') {
          this.toast(name + '입력해주세요.');
          valid = false;
        }
        else if(value === false) {
          this.toast(name + '체크해주세요.');
          valid = false;
        }

        return valid;
      },
      isMobile() {
        if(navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/webOS/i)
          || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPod/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)) return true;
        else return false;
      },
      clickAccount() {
        let valid = this.isValid(this.serviceName, '서비스 이름을');
        valid = this.isValid(this.url, 'URL을', valid);
        valid = this.isValid(this.agree.billing, '매월 정기 결제 동의에', valid);
        valid = this.isValid(this.agree.limit, '이용약관 및 하단 결제 안내사항에 동의에', valid);

        if(!valid) return;

        let basket = this.basket;
        basket.serviceName = this.serviceName;
        basket.url = this.url;
        basket.account_date = moment().format('YYYY-MM-DD');
        this.$store.commit('setBasket', basket);

        if(this.isMobile()) {
          this.isBilling = true;
        } else {
          this.isBillingPc = true;
        }
      },
      account() {
        this.serviceName = this.basket.serviceName;
        this.url = this.basket.url;
        this.account_date = this.basket.account_date;

        let result = JSON.parse(this.$route.query.result);
        let basket = this.basket;
        if(this.isMobile()) {
          basket.card = {
            num: result.cardno,
            code: result.cardcd,
            billkey: result.billkey
          };
        } else {
          basket.card = {
            num: result.CARD_Num,
            code: result.CARD_Code,
            billkey: result.CARD_BillKey
          };
        }

        this.$store.commit('setBasket', basket);
        // 서버 전송 처리

        this.$router.push('account_done');
      }
    }
  }
</script>

<style scoped>
  .lp-account-title,
  .lp-account-menu-title,
  .lp-total-price {
    color: rgb(57, 62, 70);
    font-size: 1.2rem;
  }

  .content-main-top-container {
    background-color: rgb(247, 247, 247);
  }

  .lp-account-title {
    background-color: white;
    padding: 28px;
    font-weight: 600;
    text-align: center;
  }

  .lp-account-menu-title {
    margin-bottom: 2px;
  }

  .lp-account-container {
    padding: 40px 0;
  }

  .lp-card {
    border: 1px solid rgb(229, 229, 229);
    border-radius: 3px;
    padding: 16px;
    background-color: white;
  }

  .lp-account-info {
    color: rgb(131, 131, 131);
    display: flex;
    justify-content: space-between;
    padding: 16px 12px 0 12px;
  }

  .lp-total-price {
    font-weight: 600;
  }

  .lp-input-box {
    border: 1px solid #eee;
    border-radius: 3px;
    margin-bottom: 12px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .lp-input-box .prefix,
  .lp-input-box .suffix,
  .lp-info {
    color: rgb(131, 131, 131);
    font-size: 0.9rem;
  }

  .lp-input-box .prefix {
    margin: 0 12px;
  }

  .lp-input-box .suffix {
    margin-right: 12px;
    color: rgb(255, 102, 0);
  }

  input[type='text'] {
    height: 44px;
    border: 0;
    flex: 1;
    font-size: 1rem;
    padding: 0 12px;
  }

  input[type='checkbox'] {
    border-radius: 0;
    border: 1px solid #ddd;
    margin: 0 8px 12px 0;
  }

  input:focus {
    outline: 0;
  }

  .button {
    margin-top: 20px;
  }

  .info {
    border-top: 1px solid #ddd;
    padding: 36px 0;
    white-space: pre-wrap;
    font-size: 0.9rem;
    line-height: 1.8rem;
  }

  .lp-card-title {
    color: rgb(152, 152, 152);
    font-size: 0.9rem;
    margin-bottom: 4px;
  }

  th {
    text-align: center;
  }
  td:first-child {
    text-align: center;
  }

  td {
    text-align: right;
  }

</style>
